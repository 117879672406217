import { Dialog, DialogTitle } from "@material-ui/core";
import React, { FC, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer as MUIDrawer,
  ListItem,
  List,
  ListItemText,
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import {
  Alert as AlertBox,
  amendedFrontelVideoQueue,
  frontelVideoQueue,
} from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import MessageModal from "../../modals/MessageModal/MessageModal";
import Button from "@material-ui/core/Button";

const currentTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#ebe7dd",
    },
    secondary: {
      main: "#010101",
    },
  },
});

interface FrontelQueueModalProps {
  loadingImage: boolean;
  appStore?: AppStore;
  open: boolean;
  onClose: () => void;
  alert: AlertBox | undefined;
  data: frontelVideoQueue[];
}

const FrontelQueueModal: FC<FrontelQueueModalProps> = ({
  loadingImage,
  appStore,
  open,
  onClose,
  alert,
  data,
}: FrontelQueueModalProps) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [incomingVideoQueue, setIncomingVideoQueue] =
    useState<frontelVideoQueue[]>(data);
  const [amendedVideoQueue, setAmendedVideoQueue] = useState<
    frontelVideoQueue[]
  >([]);
  const [message, setMessage] = React.useState("");
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);
  const [currentTheme, setCurrentTheme] = React.useState("dark");

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };
  const closeMessageModal = () => {
    setMessage("");
    setMessageModalOpen(false);
  };

  const handleVideoSelection = (listIndex: number, itemIndex: number) => {
    const selectedItem =
      listIndex === 0
        ? incomingVideoQueue[itemIndex]
        : amendedVideoQueue[itemIndex];

    // Move the item from the clicked list to the other list
    if (listIndex === 0) {
      setAmendedVideoQueue((prevList) => [...prevList, selectedItem]);
      setIncomingVideoQueue((prevList) =>
        prevList.filter((item, i) => i !== itemIndex),
      );
    } else {
      setIncomingVideoQueue((prevList) => [...prevList, selectedItem]);
      setAmendedVideoQueue((prevList) =>
        prevList.filter((item, i) => i !== itemIndex),
      );
    }
  };

  const handleAmendFrontelQueue = async (
    videoQueue: amendedFrontelVideoQueue[],
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    var account: string = videoQueue[0]["customer_account"];
    var panelSerialNumber = videoQueue[0]["panel_serial_number"];
    var videoQueueStr = "[";
    for (const vidIndex of videoQueue) {
      videoQueueStr += vidIndex.video_index + ", ";
    }
    videoQueueStr = videoQueueStr.slice(0, -2) + "]";
    if (token) {
      let res: string = await appStore?.functionService.amendFrontelQueue(
        token,
        account,
        panelSerialNumber,
        videoQueueStr,
      );
      if (res) {
      } else {
        setMessage("Unable to update frontel video queue");
        openMessageModal();
      }
    }
  };

  const handleSendClick = () => {
    // Extract the required details from items in amendedVideoQueue
    const orderedVideos = amendedVideoQueue.map(
      ({ panel_serial_number, customer_account, video_index }) => ({
        panel_serial_number,
        customer_account,
        video_index,
      }),
    );

    handleAmendFrontelQueue(orderedVideos);

    setAmendedVideoQueue([]);
    handleClose();
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    return () => {};
  }, [appStore, open, onClose, alert]);

  return (
    <ThemeProvider theme={currentTheme}>
      <Dialog
        onClose={() => onClose()}
        aria-labelledby="simple-dialog-title"
        open={open}
        // className="edit-dialog"
        className={`edit-dialog ${classes.smoothen_container}`}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ebe7dd",
          }}
        >
          <span className={classes.bold_text} style={{ fontSize: 17 }}>
            Display of video available queue
          </span>
        </DialogTitle>
        {!loadingImage ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#ebe7dd",
              minHeight: 250,
              maxHeight: 550,
              overflowY: "scroll",
            }}
          >
            <div style={{ backgroundColor: "#ebe7dd", width: "45%" }}>
              <List component="nav" aria-label="Current Priority Order">
                <p
                  className={classes.bold_text}
                  style={{ fontSize: 14, margin: 10 }}
                >
                  Current Priority Order
                </p>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    border: "1px solid #ddd",
                  }}
                >
                  <tbody style={{ overflowY: "scroll" }}>
                    {incomingVideoQueue.map((item, index) => (
                      <ListItem
                        button
                        selected={tabValue === index}
                        onClick={() => handleVideoSelection(0, index)}
                        key={index}
                      >
                        <td style={{ padding: "8px" }}>
                          {item.video_index}: {item.cam_name} - received:{" "}
                          {item.received_timestamp}
                        </td>
                      </ListItem>
                    ))}
                  </tbody>
                </table>
              </List>
            </div>

            <div style={{ backgroundColor: "#ebe7dd", width: "45%" }}>
              <List component="nav" aria-label="Current Priority Order 2">
                <p
                  className={classes.bold_text}
                  style={{ fontSize: 14, margin: 10 }}
                >
                  To Be Prioritised
                </p>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    border: "1px solid lightgrey",
                  }}
                >
                  <tbody style={{ overflowY: "scroll" }}>
                    {amendedVideoQueue.map((item, index) => (
                      <ListItem
                        button
                        selected={tabValue === index}
                        onClick={() => handleVideoSelection(1, index)}
                        key={index}
                      >
                        <td style={{ padding: "8px" }}>
                          {item.video_index}: {item.cam_name} - received:{" "}
                          {item.received_timestamp}
                        </td>
                      </ListItem>
                    ))}
                  </tbody>
                </table>
              </List>
            </div>

            {/* <div style={{ textAlign: "center", marginTop: 10 }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.mute_button}
                onClick={handleSendClick}
              >
                Send
              </Button>
            </div>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Button className={classes.cancel_button} variant="contained" color="primary" onClick={handleClose}>
                Cancel
              </Button>
            </div> */}
            <MessageModal
              currenttheme={currentTheme}
              open={messagemodalopen}
              onClose={closeMessageModal}
              message={message}
            />
          </div>
        ) : (
          <p>Loading</p>
        )}
        {!loadingImage && (
          <div
            style={{
              padding: 15,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              backgroundColor: "#ebe7dd",
              borderTop: "1px solid grey",
            }}
          >
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.mute_button}
                onClick={handleSendClick}
              >
                Send
              </Button>
            </div>
            &nbsp;&nbsp;
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Button
                className={classes.cancel_button}
                variant="contained"
                color="default"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default FrontelQueueModal;
