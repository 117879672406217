import { AuthService } from "./AuthService";
import {
  FunctionService,
  Classifier,
  Alert,
  HealthTimelineCount,
  Activity,
  Health,
} from "./FunctionService";
import { AppStore } from "../stores/AppStore";
const initialrequeststarttime: number = 0;
const noendtime: number = 0;
export class Helpers {
  private authService: AuthService | null = new AuthService();
  private functionService: FunctionService | null = new FunctionService();
  private timerrunning: boolean = false;
  private statstimerrunning: boolean = false;
  private timers: number[] = [];
  private statstimers: number[] = [];
  private selectedsensors: [number, number][] = [];
  private totalSensors: [number, number][] = [];
  private selectedsites: number[] = [];
  private selectedchannels: number[] = [];

  constructor() {
    this.authService = null;
    this.functionService = null;
  }
  setSelectedSensors = (selectedsensors: [number, number][]) => {
    this.selectedsensors = selectedsensors;
    console.log("setting selected sensors to : " + selectedsensors);
  };
  setTotalSensors = (totalSensors: [number, number][]) => {
    this.totalSensors = totalSensors;
    console.log("setting selected sensors to : " + totalSensors);
  };
  setSelectedSites = (selectedsites: number[]) => {
    this.selectedsites = selectedsites;
    console.log("setting selected sites to : " + selectedsites);
  };
  setSelectedChannels = (selectedchannels: number[]) => {
    this.selectedchannels = selectedchannels;
    console.log("setting selected channels to : " + selectedchannels);
  };
  setAuthService(authService: AuthService) {
    this.authService = authService;
  }
  setFunctionService(functionService: FunctionService) {
    this.functionService = functionService;
  }

  translate(locale: string, element: string, key: string): string {
    let localemap = this.functionService?.locales.find(
      (i) => i.locale === locale && i.element === element,
    );
    if (localemap) {
      const map = localemap.mapping;
      if (map.hasOwnProperty(key)) {
        return map[key];
      } else {
        return key;
      }
    } else {
      return key;
    }
  }

  async fetchHealth(appStore: AppStore, site_ids: number[]) {
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      var health = await appStore?.functionService.getHealth(
        token,
        site_ids,
        true,
      );
      if (health) {
        return health;
      }
    }
  }

  async fetchActivity(
    appStore: AppStore,
    site_ids: number[],
    start_time_filter: number,
    bin_secs: number,
    setActivityFromServer: (serveractivity: Activity) => void,
  ) {
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      var activity = await appStore?.functionService.getActivity(
        token,
        site_ids,
        start_time_filter,
        bin_secs,
      );
      if (activity && activity.success && activity.msg) {
        setActivityFromServer(activity.msg);
      }
    }
  }
  async fetchHealthTimeLine(
    appStore: AppStore,
    site_ids: number[],
    start_time_filter: number,
    bin_secs: number,
    setHealthTimeLineFromServer: (
      serverhealthtimeline: HealthTimelineCount[],
    ) => void,
  ) {
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      var healthtimeline =
        await appStore?.functionService.getHealthTimeLineForSites(
          token,
          site_ids,
          start_time_filter,
          bin_secs,
        );
      if (healthtimeline && healthtimeline.success && healthtimeline.msg) {
        setHealthTimeLineFromServer(healthtimeline.msg);
      }
    }
  }
  private statstimerid: number = 0;
  async runStatsTimer(
    appStore: AppStore,
    site_ids: number[],
    setHealthFromServer: (serverhealth: Health) => void,
    updateMuteStatuses: () => void,
  ) {
    var health = await this.fetchHealth(appStore, site_ids);
    if (health && health.success) {
      setHealthFromServer(health);
    }
    updateMuteStatuses();
    if (this.statstimerrunning) {
      this.statstimerid = window.setTimeout(
        this.runStatsTimer.bind(this),
        30000,
        appStore,
        site_ids,
        setHealthFromServer,
        updateMuteStatuses,
      );
      //clear existing timers if any
      this.statstimers.forEach((statstimerid) => {
        clearTimeout(statstimerid);
      });
      this.statstimers = [];
      this.statstimers.push(this.statstimerid);
    }
  }
  //only for sensor popup screen
  async fetchActivityForSensor(
    appStore: AppStore,
    sensor_ids: number[],
    start_time_filter: number,
    bin_secs: number,
  ) {
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      var activity = await appStore?.functionService.getActivityForSensors(
        token,
        sensor_ids,
        start_time_filter,
        bin_secs,
      );
      if (activity) {
        return activity;
      }
    }
  }
  //only for sensor popup screen
  async fetchHealthTimeLineSensor(
    appStore: AppStore,
    site_id: number,
    sensor_id_str: string,
    start_time_filter: number,
    bin_secs: number,
  ) {
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      var health = await appStore?.functionService.getHealthTimeLineForSensor(
        token,
        site_id,
        sensor_id_str,
        start_time_filter,
        bin_secs,
      );
      if (health) {
        return health;
      }
    }
  }
  //get historic alerts from rest server based on certain search parameters
  async fetchHistoricData(
    handleServerConnected: (connected: boolean) => void,
    appStore: AppStore,
    setAlerts: (alerts: Alert[]) => void,
    fromtime: number,
    totime: number,
    statuses: string[],
    setErrorMessage?: (arg: string) => void,
  ) {
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      // get all alerts except escalated
      var getalerts = await appStore?.functionService.getAlerts(
        token,
        fromtime,
        totime,
        this.selectedchannels,
        this.selectedsites,
        this.selectedsensors,
        this.totalSensors,
        statuses,
        appStore,
      );
      if (getalerts.success) {
        var alerts = getalerts.alert_data;
        if (alerts) {
          setAlerts(alerts);
          handleServerConnected(true);
        }
      } else {
        setErrorMessage && getalerts?.msg && setErrorMessage!(getalerts!.msg);
        handleServerConnected(false);
      }
    }
  }

  private timerid: number = 0;

  // this runs the timer that get alerts from the rest server
  async runTimer(
    alertrole: boolean,
    escalatedrole: boolean,
    handleServerConnected: (connected: boolean) => void,
    fromtime: number,
    appStore: AppStore,
    setAlerts: (alerts: Alert[]) => void,
    setEscalatedAlerts: (alerts: Alert[]) => void,
    clearOldUpdateEvents: () => void,
    setErrorMessage?: (arg: string) => void,
  ) {
    //console.log("timer running ... ");
    let selectedsites = this.selectedsites;
    let selectedsensors = this.selectedsensors;
    let selectedchannels = this.selectedchannels;
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      if (alertrole) {
        let getalerts = await appStore?.functionService.getAlerts(
          token,
          fromtime,
          noendtime,
          this.selectedchannels,
          this.selectedsites,
          this.selectedsensors,
          this.totalSensors,
          ["unreviewed"],
          appStore,
        );
        if (getalerts.success) {
          fromtime = getalerts.timefilter;
          //get unreviewed alerts for time since last call less 5 seconds to give elastic a chance to save the alert.
          fromtime = fromtime - 5;
          let alerts = getalerts.alert_data;
          if (alerts && this.timerrunning) {
            //console.log("got alerts ... ");
            //only valid if site/channel/sensor selection not changed
            if (
              selectedsites === this.selectedsites &&
              selectedsensors === this.selectedsensors &&
              selectedchannels === this.selectedchannels
            ) {
              // console.log('got alerts ... ');
              // console.log('sites ' + selectedsites);
              // console.log('channels ' + selectedchannels);
              // console.log('sensors ' + selectedsensors);
              // alerts.forEach((alert) => {
              //   console.log(
              //     'alert ' + alert.message + ' : ' + alert.sensor_id_str
              //   );
              // });
              setAlerts(alerts);
              handleServerConnected(true);
            } else {
              // console.log("not setting alerts ... config changed");
            }
          }
        } else {
          setErrorMessage && getalerts?.msg && setErrorMessage!(getalerts!.msg);
          handleServerConnected(false);
          if (getalerts.code === 401) {
            this.authService?.signOut();
          }
        }
      }
      //get escalated alerts for all time for channels where applicable
      if (escalatedrole) {
        var escalatedfromtime =
          new Date().getTime() / 1000 - 365 * 24 * 60 * 60 * 3;
        let getalerts = await appStore?.functionService.getAlerts(
          token,
          escalatedfromtime,
          noendtime,
          this.selectedchannels,
          this.selectedsites,
          this.selectedsensors,
          this.totalSensors,
          ["escalated"],
          appStore,
        );
        if (getalerts.success) {
          let alerts = getalerts.alert_data;
          if (alerts && this.timerrunning) {
            //only valid if site/channel/sensor selection not changed
            // console.log("got escalated alerts ... ");
            if (
              selectedsites === this.selectedsites &&
              selectedsensors === this.selectedsensors &&
              selectedchannels === this.selectedchannels
            ) {
              //console.log("setting escalated alerts ... ");
              setEscalatedAlerts(alerts);
              handleServerConnected(true);
            } else {
              //console.log("not setting escalated alerts ... config changed");
            }
          }
        } else {
          setErrorMessage && getalerts?.msg && setErrorMessage!(getalerts!.msg);
          handleServerConnected(false);
        }
      }
    } else {
      handleServerConnected(false);
    }
    clearOldUpdateEvents();
    if (this.timerrunning) {
      this.timerid = window.setTimeout(
        this.runTimer.bind(this),
        3000,
        alertrole,
        escalatedrole,
        handleServerConnected,
        fromtime,
        appStore,
        setAlerts,
        setEscalatedAlerts,
        clearOldUpdateEvents,
      );
      //clear existing timers if any
      this.timers.forEach((timerid) => {
        clearTimeout(timerid);
      });
      this.timers = [];
      this.timers.push(this.timerid);
    }
  }
  //This starts the timer that gets alerts from the rest server
  startTimer(
    alertrole: boolean,
    escalatedrole: boolean,
    handleServerConnected: (connected: boolean) => void,
    appStore: AppStore,
    setAlerts: (alerts: Alert[]) => void,
    setEscalatedAlerts: (alerts: Alert[]) => void,
    clearOldUpdateEvents: () => void,
    setErrorMessage?: (arg: string) => void,
  ) {
    this.timers = [];
    this.timerrunning = true;
    this.runTimer(
      alertrole,
      escalatedrole,
      handleServerConnected,
      initialrequeststarttime,
      appStore,
      setAlerts,
      setEscalatedAlerts,
      clearOldUpdateEvents,
      setErrorMessage,
    );
  }
  //This stops the timer that gets alerts from the rest server and clears any possible istances thereof that might be stuck in memory
  stopTimer() {
    clearTimeout(this.timerid);
    let idx = this.timers.indexOf(this.timerid);
    this.timers.splice(idx, 1);
    //clear existing timers if any
    this.timers.forEach((timerid) => {
      clearTimeout(timerid);
    });
    this.timers = [];
    this.timerrunning = false;
    console.log("STOP Timer called");
  }
  //This starts the timer that gets stats from the rest server
  startStatsTimer(
    appStore: AppStore,
    site_ids: number[],
    setHealthFromServer: (healthfromserver: Health) => void,
    updateMuteStatuses: () => void,
  ) {
    this.statstimers = [];
    this.statstimerrunning = true;
    this.runStatsTimer(
      appStore,
      site_ids,
      setHealthFromServer,
      updateMuteStatuses,
    );
  }
  //This stops the timer that gets stats from the rest server and clears any possible istances thereof that might be stuck in memory
  stopStatsTimer() {
    clearTimeout(this.statstimerid);
    let idx = this.statstimers.indexOf(this.statstimerid);
    this.statstimers.splice(idx, 1);
    //clear existing timers if any
    this.statstimers.forEach((statstimerid) => {
      clearTimeout(statstimerid);
    });
    this.statstimers = [];
    this.statstimerrunning = false;
    console.log("STOP STATS Timer called");
  }
}
